import header from './html/header.html';
import footer from './html/footer.html';
import { searchInit } from '../../search_common';

import {
  appendNewsList,
  searchGames,
  searchBlogs,
  searchSupport,
  updateGlobalSearch,
  clearGlobalSearch
} from '../../utils';

export default function (conf) {
  const $header = $($(header));
  const $footer = $($(footer));
  $('body').append($header);
  $('body').append($footer);
  // 动态导入 footer 模块
  import('../../footer');
  // 底部新闻
  appendNewsList(35328, $footer.find('.footer-ajax.news'));
  // 底部常见问题
  appendNewsList(
    '35522,35521,35520,35519,35518,35517,35516,35515,35514',
    $footer.find('.footer-ajax.faq')
  );

  const handleSearch = (() => {
    let timer = 0;
    let feed = 0;
    return () => {
      if (timer) window.clearTimeout(timer);
      feed += 1;
      timer = window.setTimeout(() => {
        var str = $('.nav_search_input').val().trim();

        if (!str) {
          clearGlobalSearch();
          $('.nav_search_box').slideUp(500);
          $('.icon_clear').css({ display: 'none' });
          return;
        }
        $('.icon_clear').css({ display: 'block' });
        const _feed = feed;
        Promise.all([
          searchGames(str, 'vi'),
          searchBlogs(str, '35325,35328,36813,36814'),
          searchSupport(str, '35522,35521,35520,35519,35518,35517,35516,35515,35514')
        ]).then(([games, blogs, faqs]) => {
          if (_feed !== feed) return;
          updateGlobalSearch(
            { games, blogs, faqs },
            {
              empty: `Không có kết quả tìm kiếm <span>${str}</span>, đổi từ khoá khác`,
              'empty-games': 'Không có <span>trò chơi</span> liên quan, hãy thử từ khóa khác',
              'empty-blogs': 'Không có <span>bài viết</span> liên quan, hãy thử từ khóa khác',
              'empty-faqs':
                'Không có <span>câu hỏi thường gặp</span> liên quan, hãy thử từ khóa khác',
              'games-title': 'Games',
              'blogs-title': 'Blog',
              'faqs-title': 'Hỗ trợ',
              'see-all': 'Kiểm tra'
            }
          );
          if (!$('.nav_search').hasClass('on')) return;
          $('.nav_search_box').slideDown(500);
        });
      }, 200);
    };
  })();

  // 搜索公用部分函数
  searchInit(handleSearch);

}
