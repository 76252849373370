import de from './de';
import zh from './zh';
import tw from './tw';
import en from './en';
import jp from './jp';
import kr from './kr';
import pt from './pt';
import fr from './fr';
import ru from './ru';
import es from './es';
import vi from './vi';
import id from './id';
import th from './th';

export default {
  de,
  zh,
  tw,
  en,
  jp,
  kr,
  pt,
  fr,
  ru,
  es,
  vi,
  id,
  th,
};
