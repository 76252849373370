const t = (str, kv) => {
  return str.replace(/\{(.*?)\}/, function(_, k) {
    return kv[k];
  });
}

export const fetchNewsList = (data, success) => {
  $.ajax({
    //底部新闻
    url: 'https://sixhorse.game.163.com/news/outer/newslist.do',
    type: 'GET',
    data: data,
    dataType: 'jsonp',
    success: success
  });
}
export const searchNewsList = (data, success, error) => {
  $.ajax({
    url: 'https://sixhorse.game.163.com/news/outer/newslist_search.do',
    type: 'GET',
    dataType: "jsonp",
    data: data,
    jsonp: "callback",
    success: success,
    error: error
  });
}
export const appendNewsList = (ids, $dom) => {
  fetchNewsList({ contentkind: ids, limit: 5}, function(data) {
    let html = ""
    data.result.forEach(element => {
      html += `
          <a href="${element.url}" target="_blank" title="${element.title}">${element.title}</a>
          `
    });
    $($dom).html(html);
  });
}
export const searchGames = (str, lang) => {
  return new Promise((resolve) => {
    $.ajax({
      url: 'https://store-api-oversea.webapp.easebar.com/api/website/app/search',
      type: 'GET',
      dataType: "json",
      data: { keyword: str, language_code: lang },
      jsonp: "callback",
      success: function (data) {
        const { code } = data;
        if (!!code) return resolve([]);
        resolve(data.data.result.slice(0, 5).map(item => ({
          title: item.name, icon: item.icon, url: item.detail_page_url,
        })));
      },
      error: function() {
        resolve([]);
      }
    });
  })
}
export const searchBlogs = (str, ids) => {
  return new Promise((resolve) => {
    searchNewsList({
      contentkind: ids,
      keyword: str,
      limit: 5,
      start: 1,
    },
    function (data) {
      const { result, succ } = data;
      if (!succ) return resolve([]);
      resolve(result.slice(0, 5).map(item => ({
        title: item.title, icon: item.imageUrl, url: item.url
      })));
    },
    function() { resolve([]) });
  })
}
export const searchSupport = (str, ids) => {
  return new Promise((resolve) => {
    searchNewsList({
      contentkind: ids,
      keyword: str,
      limit: 5,
      start: 1,
    },
    function (data) {
      const { result, succ } = data;
      if (!succ) return resolve([]);
      resolve(result.slice(0, 5).map(item => ({
        title: item.title, icon: item.imageUrl, url: item.url
      })));
    },
    function() { resolve([]) });
  })
}
export const updateGlobalSearch = ({ games, blogs, faqs }, locale) => {
  let content = '';
  if (!games.length && !blogs.length && !faqs.length) {
    content = `<div class="nav_search_box_empty">${locale['empty']}</div>`;
    $('.nav_search_box').html(content);
    return;
  }
  if (games.length) {
    content += `
    <div class="nav_search_box_games">
      <h3>${locale['games-title']}<span class="nav_search_box_more" data-type="game">${locale['see-all']} <i class="icon_more"></i></span></h3>
      <div class="nav_search_box_games_content">
        <ul>${games.map(({ title, icon, url})=> `<li><a href="${url}"><p><i style="background-image:url(${icon})"></i>${title}</p></a></li>`).join('')}</ul>
      </div>
    </div>`
  } else {
    content += `<div class="nav_search_box_empty">${locale['empty-games']}</div>`
  }
  if (blogs.length) {
    content += `
    <div class="nav_search_box_blogs">
      <h3>${locale['blogs-title']}<span class="nav_search_box_more" data-type="blogs">${locale['see-all']} <i class="icon_more"></i></span></h3>
      <div class="nav_search_box_blogs_content">
        <ul>${blogs.map(({ title, icon, url})=> `<li><a href="${url}"><p><i style="background-image:url(${icon})"></i>${title}</p></a></li>`).join('')}</ul>
      </div>
    </div>`
  } else {
    content += `<div class="nav_search_box_empty">${locale['empty-blogs']}</div>`
  }
  if (faqs.length) {
    content += `
    <div class="nav_search_box_faqs">
      <h3>${locale['faqs-title']}<span class="nav_search_box_more" data-type="support">${locale['see-all']} <i class="icon_more"></i></span></h3>
      <div class="nav_search_box_faqs_content">
        <ul>${faqs.map(({ title, icon, url})=> `<li><a href="${url}"><p><i style="background-image:url(${icon})"></i>${title}</p></a></li>`).join('')}</ul>
      </div>
    </div>`
  } else {
    content += `<div class="nav_search_box_empty">${locale['empty-faqs']}</div>`
  }

  content = `<div class="nav_search_box_result">${content}</div>`;

  $('.nav_search_box').html(content);
}
export const clearGlobalSearch = () => {
  $('.nav_search_box').html('');
}

export const getScript = (() => {
  const loadedScripts = [];
  return (url, attrs, callback) => {
    if (loadedScripts.indexOf(url) >= 0) {
      callback && callback();
    } else {
      var head = document.getElementsByTagName('head')[0],
        js = document.createElement('script');
      js.setAttribute('type', 'text/javascript');
      js.setAttribute('src', url);
      if (attrs) Object.keys(attrs).forEach((key) => js.setAttribute(key, attrs[key]))

      if (document.all) { //IE
        js.onreadystatechange = function () {
          if (js.readyState == 'loaded' || js.readyState == 'complete') {
            callbackFn();
          }
        }
      } else {
        js.onload = function () {
          callbackFn();
        }
      }
      head.appendChild(js);
      //执行回调
      var callbackFn = function () {
        if (typeof callback === 'function') {
          loadedScripts.push(url);
          callback();
        }
      };
    }
  }}
)();
