import header from './html/header.html';
import footer from './html/footer.html';
import { searchInit } from '../../search_common';

import {
  appendNewsList,
  searchGames,
  searchBlogs,
  searchSupport,
  updateGlobalSearch,
  clearGlobalSearch
} from '../../utils';

export default function (conf) {
  const $header = $($(header));
  const $footer = $($(footer));
  $('body').append($header);
  $('body').append($footer);
  // 动态导入 footer 模块
  import('../../footer');
  // 底部新闻
  appendNewsList(36148, $footer.find('.footer-ajax.news'));
  // 底部常见问题
  appendNewsList(
    '36152,36153,36154,36155,36156,36157,36158,36159,36160',
    $footer.find('.footer-ajax.faq')
  );

  const handleSearch = (() => {
    let timer = 0;
    let feed = 0;
    return () => {
      if (timer) window.clearTimeout(timer);
      feed += 1;
      timer = window.setTimeout(() => {
        var str = $('.nav_search_input').val().trim();

        if (!str) {
          clearGlobalSearch();
          $('.nav_search_box').slideUp(500);
          $('.icon_clear').css({ display: 'none' });
          return;
        }
        $('.icon_clear').css({ display: 'block' });
        const _feed = feed;
        Promise.all([
          searchGames(str, 'th'),
          searchBlogs(str, '36144,36148,36805,36806'),
          searchSupport(str, '36152,36153,36154,36155,36156,36157,36158,36159,36160')
        ]).then(([games, blogs, faqs]) => {
          if (_feed !== feed) return;
          updateGlobalSearch(
            { games, blogs, faqs },
            {
              empty: `ไม่มีผลลัพธ์สำหรับ <span>${str}</span> ลองใช้คำสำคัญอื่น`,
              'empty-games': 'ไม่มี <span>เกม</span> ที่เกี่ยวข้อง ลองใช้คีย์เวิร์ดอื่น',
              'empty-blogs': 'ไม่มี <span>บทความ</span> ที่เกี่ยวข้อง ลองใช้คีย์เวิร์ดอื่น',
              'empty-faqs': 'ไม่มี <span>FAQ</span> ที่เกี่ยวข้อง ลองใช้คีย์เวิร์ดอื่น',
              'games-title': 'เกม',
              'blogs-title': 'บล็อก',
              'faqs-title': 'สนับสนุน',
              'see-all': 'ดูทั้งหมด'
            }
          );
          if (!$('.nav_search').hasClass('on')) return;
          $('.nav_search_box').slideDown(500);
        });
      }, 200);
    };
  })();

  // 搜索公用部分函数
  searchInit(handleSearch);

}
