import header from './html/header.html';
import footer from './html/footer.html';
import { searchInit } from '../../search_common';
import {
  appendNewsList,
  // searchGames,
  // searchBlogs,
  searchSupport,
  updateGlobalSearch,
  clearGlobalSearch
} from '../../utils';
export default function (conf) {
  const $header = $($(header));
  const $footer = $($(footer));
  $('body').append($header);
  $('body').append($footer);
  // 动态导入 footer 模块
  import('../../footer');
  // 底部新闻
  appendNewsList(30606, $footer.find('.footer-ajax.news'));
  // 底部常见问题
  appendNewsList(
    '35480,35478,35473,35474,35475,35472,35479,35476,35477',
    $footer.find('.footer-ajax.faq')
  );

  const handleSearch = (() => {
    let timer = 0;
    let feed = 0;
    return () => {
      if (timer) window.clearTimeout(timer);
      feed += 1;
      timer = window.setTimeout(() => {
        var str = $('.nav_search_input').val().trim();

        if (!str) {
          clearGlobalSearch();
          $('.nav_search_box').slideUp(500);
          $('.icon_clear').css({ display: 'none' });
          return;
        }
        $('.icon_clear').css({ display: 'block' });
        const _feed = feed;
        Promise.all([
          // searchGames(str, 'de'),
          // searchBlogs(str, '35086,30606,36795,36796'),
          searchSupport(str, '41243,41244,41242,41248')
        ]).then(([faqs]) => {
          const games = '';
          const blogs = '';
          if (_feed !== feed) return;
          updateGlobalSearch(
            { games, blogs, faqs },
            {
              empty: `Keine Ergebnisse für <span>${str}</span>,  versuchen Sie einen anderen Suchbegriff`,
              // 'empty-games': 'Keine verwandten <span>spiel</span>, versuchen Sie ein anderes Schlüsselwort',
              // 'empty-blogs': 'Keine verwandten <span>articles</span>, versuchen Sie ein anderes Schlüsselwort',
              'empty-games': '',
              'empty-blogs': '',
              'empty-faqs':
                'Nicht relevant <span>FAQ</span>, versuchen Sie einen anderen Suchbegriff',
              // 'games-title': 'Spiele',
              // 'blogs-title': 'Nachrichten',
              'faqs-title': 'FAQ',
              'see-all': 'Alle anzeigen'
            }
          );
          if (!$('.nav_search').hasClass('on')) return;
          $('.nav_search_box').slideDown(500);
        });
      }, 200);
    };
  })();

  // 搜索公用部分函数
  searchInit(handleSearch);
}
