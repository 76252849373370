import header from './html/header.html';
import footer from './html/footer.html';
import { searchInit } from '../../search_common';

import {
  searchGames,
  searchBlogs,
  searchSupport,
  updateGlobalSearch,
  clearGlobalSearch
} from '../../utils';
let NIE = null;

export default function (conf) {
  const $header = $($(header));
  const $footer = $($(footer));
  $('body').append($header);
  $('body').append($footer);
  // 动态导入 footer 模块
  import('../../footer');
  if (!NIE) {
    const script = document.createElement('script');
    script.src = '//comm.res.easebar.com/js/jquery(mixNIE).1.11.js';
    document.body.appendChild(script);
    script.onload = function () {
      NIE = window.nie;
      window.nie.config.copyRight.setWhite();
    };
  }
  const handleSearch = (() => {
    let timer = 0;
    let feed = 0;
    return () => {
      if (timer) window.clearTimeout(timer);
      feed += 1;
      timer = window.setTimeout(() => {
        var str = $('.nav_search_input').val().trim();
        if (!str) {
          clearGlobalSearch();
          $('.icon_clear').css({ display: 'none' });
          $('.nav_search_box').slideUp(500);
          return;
        }
        $('.icon_clear').css({ display: 'block' });
        const _feed = feed;
        Promise.all([
          searchGames(str, 'zh'),
          searchBlogs(str, '35123,34863,36811,36812'),
          searchSupport(str, '35505,35506,35507,35508,35509,35510,35511,35512,35513')
        ]).then(([games, blogs, faqs]) => {
          if (_feed !== feed) return;
          updateGlobalSearch(
            { games, blogs, faqs },
            {
              empty: `沒有<span>${str}</span>的结果，换个关键词试试`,
              'empty-games': '沒有相关的<span>游戏</span>，换个关键词试试',
              'empty-blogs': '沒有相关的<span>资讯</span>，换个关键词试试',
              'empty-faqs': '沒有相关的<span>常见问题</span>，换个关键词试试',
              'games-title': '游戏',
              'blogs-title': '资讯',
              'faqs-title': '常见问题',
              'see-all': '查看全部'
            }
          );
          if (!$('.nav_search').hasClass('on')) return;
          $('.nav_search_box').slideDown(500);
        });
      }, 200);
    };
  })();

  // 搜索公用部分函数
  searchInit(handleSearch);

}
