import '../styles/main.scss';
import initiator from './locales';
import { getLanguageExp, isMobile, langData } from './common';
(function (global) {
  global.MUMUSDK = {
    mode: 'desktop',
    language: 'en',
    excludeLanguage: [],
    onSelectLanguage: null,
    init(conf) {
      const langExp = getLanguageExp();
      const pathname = window.location.pathname.split('/');
      const current_lang =
        pathname.length > 1
          ? pathname[1].length > 1 && pathname[1].match(langExp)
            ? pathname[1]
            : ''
          : '';
      const lang = current_lang || conf.language || 'zh';
      const self = this;
      this.language = lang;
      $(function () {
        $('body').find('.global_footer').remove();
        $('body').find('.global_header').remove();
        const matched = langData[self.language] ? self.language : Object.keys(langData)[0];
        initiator[matched]({});
        searchMoreClick(lang);
        instNav(lang, conf);
      });
    }
  };
})(window);
// 搜索点击更多
function searchMoreClick(language) {
  const _language = language == 'zh' || language == 'en' ? '' : `/${language}`;
  $('.nav_search').on('click', '.nav_search_box_more', function () {
    const type = $(this).data('type');
    const input = $('.nav_search_input').val();
    const url = `${_language}/search/?data=${input}&className=${type}_search_all%20all`;
    window.location.href = url;
  });
  $('.nav_search_input').on('keypress', function (event) {
    if (event.keyCode == '13') {
      // 回车
      var input = $('.nav_search_input').val().trim();
      if (input.length > 0) {
        const url = `${_language}/search/?data=${input}`;
        window.location.href = url;
      }
    }
  });
}
// nav.js
function instNav(conf_lang, conf) {
  console.log(conf);
  //判断当前设备是否为移动端
  if (isMobile()) {
    $('.top-bar__download_m').addClass('show');
  } else {
    $('.top-bar__download_pc').addClass('show');
  }
  const langExp = getLanguageExp();
  const pathname = window.location.pathname.split('/');
  const current_lang = conf_lang;
  let html_string = ``;
  // 过滤掉需要隐藏的语言
  const visibleLanguages = Object.keys(langData).filter(
    (lang) => !conf.excludeLanguage || !conf.excludeLanguage.includes(lang)
  );
  // 渲染多语言列表
  visibleLanguages.forEach((lang) => {
    html_string += `<li data-lang="${lang}"><a href="javascript:;" ${
      lang === current_lang ? `class="actived"` : ''
    } data-lang="${lang}">${langData[lang]}</a></li>`;
  });
  $('.Jlanguage_data').html(html_string);
  $('.Jcur_language').html(langData[current_lang]);
  $('.Jlanguage_data a').click(function () {
    let lang = $(this).data('lang');
    let baseUrl = lang === 'zh' ? 'https://mumu.163.com' : '';
    let currentPath = window.location.pathname;
    let newPath;

    // 移除开头的语言代码（如果存在）
    if (pathname.length > 1 && pathname[1].length > 1 && langExp.test(pathname[1])) {
      currentPath = '/' + pathname.slice(2).join('/');
    }
    // 检查是否为 games 路径
    const isGamesPath = $('#pagegamesurl').length > 0;
    // 根据新语言构建新路径
    if (lang === 'en') {
      newPath = isGamesPath ? '/en' + currentPath : currentPath;
    } else if (lang === 'zh') {
      newPath = currentPath;
    } else {
      newPath = '/' + lang + currentPath;
    }

    let newPage = baseUrl + newPath + window.location.search;
    skipPage(newPage, lang);
  });
  topbarEvent();
}
function skipPage(url, lang) {
  if (lang == 'zh') {
    window.location.href = 'https://mumu.163.com';
  } else if (window.location.href !== url) {
    $.ajax({
      url,
      type: 'GET',
      data: {},
      success: function () {
        window.location.href = url;
      },
      error: () => {
        if (/zh-tw/.test(lang)) {
          window.location.href = '/' + lang + '/blog' + window.location.search;
        } else {
          window.location.href = (lang ? '/' + lang + '/' : '/') + window.location.search;
        }
      }
    });
  }
}
function topbarEvent() {
  const $topbarMore = $('#topbar_more');
  $('.top-bar__item-title, .top-bar .hp-btn, .Jlogo').hover(function () {
    const id = $(this).hasClass('Jnav_expend') ? $(this).data('id') : '';
    productNavLeave(id);
  });

  $('.Jnav_expend').mouseover(function () {
    if ($topbarMore.hasClass('show') && window.innerWidth < 1360) {
      return;
    }
    $(this).next().addClass('expended');
    $(this).addClass('expended');
    $('.top-bar').addClass('is-expended');
    $('.top-bar__mask').addClass('show');
  });
  $('.top-bar').mouseleave(function () {
    productNavLeave();
  });

  $('.top-bar__item').each(function () {
    if (window.location.pathname.match($(this).find('.top-bar__item-title').attr('href'))) {
      $('.top-bar__item').removeClass('actived');
      $(this).addClass('actived');
    }
  });

  //expended nav
  let isExpended = false;
  let isAnimating = false;
  $('.JnavMore').click(function () {
    if (isAnimating) return;
    isAnimating = true;
    if (!isExpended) {
      $(this).addClass('expended');
      $topbarMore.addClass('show-box');
      setTimeout(() => {
        isAnimating = false;
        $topbarMore.addClass('show');
      }, 0);
    } else {
      $topbarMore.removeClass('show');
      setTimeout(() => {
        isAnimating = false;
        $topbarMore.removeClass('show-box');
      }, 500);
      $(this).removeClass('expended');
    }
    isExpended = !isExpended;
  });

  //expended subnav
  $('.Jnav_expend').click(function (e) {
    if ($topbarMore.hasClass('show')) {
      e.preventDefault();
      let isSubExpended = $(this).data('status');
      if (!isSubExpended) {
        $(this).parent().addClass('expended');
      } else {
        $(this).parent().removeClass('expended');
      }
      $(this).data('status', !isSubExpended);
    }
  });

  const $topbar = $('.top-bar');
  $(window).scroll(function () {
    const scrollTop = $(this).scrollTop();
    if (scrollTop > 70) {
      $topbar.addClass('is-pinned');
    } else {
      $topbar.removeClass('is-pinned');
    }
  });

  // language
  $('.nav_hover')
    .on('mouseenter', function () {
      var $subNavWrap = $(this).find('>.sub-nav__wrap');
      if ($subNavWrap.length) {
        $subNavWrap.slideDown(200);
      } else {
        $('.sub-nav__wrap').stop(true, true).slideUp(200);
      }
      productNavLeave();
    })
    .on('mouseleave', function () {
      $(this).find('>.sub-nav__wrap').stop(true, true).slideUp(200);
    });
}

function productNavLeave(id) {
  if (id) {
    $('.top-bar__expend').each(function () {
      if ($(this).attr('id') !== id) {
        $(this).removeClass('expended');
      }
    });
    $('.Jnav_expend').each(function () {
      if ($(this).attr('id') !== id) {
        $(this).removeClass('expended');
      }
    });
  } else {
    $('.top-bar__expend').removeClass('expended');
    $('.Jnav_expend').removeClass('expended');
    $('.top-bar').removeClass('is-expended');
    $('.top-bar__mask').removeClass('show');
  }
}
