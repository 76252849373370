const langData = {
  // ru: 'Pусский',
  // es: 'Español',
  // vi: 'Tiếng Việt',
  // id: 'Bahasa Indonesia',
  // th: 'ไทย',
  // pt: 'Português',
  en: 'English',
  jp: '日本語',
  kr: '한국어',
  fr: 'Français',
  de: 'Deutsch',
  tw: '繁體中文',
  zh: '简体中文'
};
function getLanguageExp() {
  return /^(en|jp|kr|de|fr|tw|zh)$/i;
}
const debounce = function (fn, wait) {
  // let timeout = null;
  // return function() {
  //     if (timeout !== null) clearTimeout(timeout);
  //     timeout = setTimeout(fn, wait);
  // }
  let timer, result, refresh;

  return function cb(...args) {
    if (!result) {
      result = new Promise((resolve) => {
        refresh = () => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            timer = result = refresh = null;
            resolve(fn.apply(this, args));
          }, wait);
        };

        refresh();
      });
    } else if (refresh) {
      refresh();
    }

    return result;
  };
};

let $btnTop = null;

try {
  $btnTop = $('#btn_top');
} catch (error) {
  console.log(error);
}

// back to the top of the page
const btnTopEvent = () => {
  $btnTop.click(() => {
    $('body , html').animate(
      {
        scrollTop: 0
      },
      300
    );
  });
};
let timer = null;
const btnTopShowHandle = (scrollTop) => {
  if (scrollTop > 600) {
    $btnTop.css('display', 'block');
    timer = setTimeout(() => {
      $btnTop.addClass('show');
    }, 100);
  } else {
    clearTimeout(timer);
    $btnTop.removeClass('show');
    timer = setTimeout(() => {
      $btnTop.hide();
    }, 0);
  }
};

function isMobile() {
  const is_mobile = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );

  const touchable = !!(
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch)
  );
  return is_mobile || touchable;
}

function loadScript(url, callback, errorHandle) {
  let script = document.createElement('script');
  script.type = 'text/javascript';
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState == 'complete' || script.readyState == 'loaded') {
        callback();
        script.onreadystatechange = null;
      }
    };
  } else {
    script.onload = function () {
      callback();
    };
  }
  script.onerror = function () {
    errorHandle && errorHandle();
  };
  script.src = url;
  document.head.appendChild(script);
}

const isSupportWebp = function () {
  try {
    return (
      document.createElement('canvas').toDataURL('image/webp', 0.5).indexOf('data:image/webp') === 0
    );
  } catch (err) {
    return false;
  }
};

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function getAppsIndex(type) {
  let index = 0;
  switch (type) {
    case 'entertainment_streaming':
      index = 0;
      break;
    case 'sports':
      index = 1;
      break;
    case 'social_media':
      index = 2;
      break;
    default:
      break;
  }
  return index;
}

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toGMTString();
  document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=/';
}

function getCookie(cname) {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return '';
}

function isChannelPage() {
  return !!(
    getUrlParam('channel') ||
    ($('#ss_channel_name').length && $('#ss_channel_name').html().length > 0)
  );
}

function isBlogPage() {
  return (
    $('#news_page').length > 0 &&
    $('#news_page').data('id') &&
    window.location.href.indexOf('/blog/') > -1
  );
}

function isAppsDetailPage() {
  return $('#template_detail_page').length > 0;
}

function getUrlParam(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}

function getChannelName() {
  return getUrlParam('channel') || $('#ss_channel_name').html();
}

function getTrueChannelName() {
  const channel = getCookie('vpn_channel');
  let channelName = 'win'; // defualt channel
  if (channel) {
    channelName = channel;
  } else {
    //Channel page
    if (isChannelPage()) {
      channelName = getChannelName();
    }
    // blog page
    else if (isBlogPage()) {
      channelName = 'blog' + $('#news_page').data('id');
    }

    // detail page
    else if (isAppsDetailPage()) {
      const match = window.location.pathname.match(/(apps|devices|regions)\/([^/]+)/);
      const gname = match ? match[2] : null;
      channelName = gname;
    }
  }
  return channelName;
}

function randomStr(prefix, length = 10) {
  let result = '';
  do {
    result += Math.random().toString(36).slice(2);
  } while (result.length < length);

  result = result.slice(0, length) + ('00000' + Date.now()).slice(-6);

  return prefix ? prefix + '_' + result : result;
}

function getUtcOffset() {
  const now = new Date();
  const localOffset = now.getTimezoneOffset() * 60000;
  const utcTime = now.getTime() + localOffset;
  const utcOffset = new Date(utcTime).getTimezoneOffset() / 60;
  return utcOffset;
}

function formatDate(date = Date.now(), formation = '') {
  date = new Date(date);

  const values = {
    Y: date.getFullYear(),
    M: date.getMonth() + 1,
    D: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds()
  };

  if (Array.isArray(formation)) {
    let result = {};

    formation.forEach((match) => {
      const value = values[match[0]];

      if (match.length > value.toString().length) {
        result = (new Array(match.length).join('0') + result).slice(-match.length);
      }

      result[match] = value;
    });

    return result;
  }

  return formation.replace(/Y+|M+|D+|h+|m+|s+/g, function (match) {
    let result = values[match[0]];

    if (match.length > result.toString().length) {
      result = (new Array(match.length).join('0') + result).slice(-match.length);
    }

    return result;
  });
}

function addCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function toFixed(num, len) {
  return (Math.round(Number(num) * Math.pow(10, len)) / Math.pow(10, len)).toFixed(len);
}

export {
  langData,
  getLanguageExp,
  debounce,
  btnTopEvent,
  btnTopShowHandle,
  loadScript,
  isSupportWebp,
  isMobile,
  getParameterByName,
  getAppsIndex,
  setCookie,
  getCookie,
  isChannelPage,
  isBlogPage,
  getChannelName,
  getTrueChannelName,
  getUrlParam,
  randomStr,
  getUtcOffset,
  formatDate,
  addCommas,
  toFixed
};
