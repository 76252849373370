import header from './html/header.html';
import footer from './html/footer.html';
import { searchInit } from '../../search_common';

import {
  appendNewsList,
  searchGames,
  searchBlogs,
  searchSupport,
  updateGlobalSearch,
  clearGlobalSearch
} from '../../utils';

export default function (conf) {
  const $header = $($(header));
  const $footer = $($(footer));
  $('body').append($header);
  $('body').append($footer);
  // 动态导入 footer 模块
  import('../../footer');
  // 底部新闻
  appendNewsList(35333, $footer.find('.footer-ajax.news'));
  // 底部常见问题
  appendNewsList(
    '35487,35488,35489,35490,35491,35492,35493,35494,35495',
    $footer.find('.footer-ajax.faq')
  );

  const handleSearch = (() => {
    let timer = 0;
    let feed = 0;
    return () => {
      if (timer) window.clearTimeout(timer);
      feed += 1;
      timer = window.setTimeout(() => {
        var str = $('.nav_search_input').val().trim();

        if (!str) {
          clearGlobalSearch();
          $('.nav_search_box').slideUp(500);
          $('.icon_clear').css({ display: 'none' });
          return;
        }
        $('.icon_clear').css({ display: 'block' });
        const _feed = feed;
        Promise.all([
          searchGames(str, 'id'),
          searchBlogs(str, '35086,30606,36795,36796'),
          searchSupport(str, '35480,35478,35473,35474,35475,35472,35479,35476,35477')
        ]).then(([games, blogs, faqs]) => {
          if (_feed !== feed) return;
          updateGlobalSearch(
            { games, blogs, faqs },
            {
              empty: `Tidak ada hasil <span>${str}</span>, coba kata kunci lain`,
              'empty-games': 'Tidak ada <span>game</span> terkait, coba kata kunci lain',
              'empty-blogs': 'Tidak ada <span>artikel</span> terkait, coba kata kunci lain',
              'empty-faqs': 'Tidak ada <span>FAQ</span> terkait, coba kata kunci lain',
              'games-title': 'Permainan',
              'blogs-title': 'Blog',
              'faqs-title': 'Support',
              'see-all': 'Lihat semua'
            }
          );
          if (!$('.nav_search').hasClass('on')) return;
          $('.nav_search_box').slideDown(500);
        });
      }, 200);
    };
  })();

  // 搜索公用部分函数
  searchInit(handleSearch);

}
