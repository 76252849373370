import header from './html/header.html';
import footer from './html/footer.html';
import { searchInit } from '../../search_common';

import {
  appendNewsList,
  searchGames,
  searchBlogs,
  searchSupport,
  updateGlobalSearch,
  clearGlobalSearch
} from '../../utils';

export default function (conf) {
  const $header = $($(header));
  const $footer = $($(footer));
  $('body').append($header);
  $('body').append($footer);
  // 动态导入 footer 模块
  import('../../footer');
  // 底部新闻
  appendNewsList(30601, $footer.find('.footer-ajax.news'));
  // 底部常见问题
  appendNewsList(
    '36316,36317,36318,36319,36320,36321,36322,36323,36324',
    $footer.find('.footer-ajax.faq')
  );

  const handleSearch = (() => {
    let timer = 0;
    let feed = 0;
    return () => {
      if (timer) window.clearTimeout(timer);
      feed += 1;
      timer = window.setTimeout(() => {
        var str = $('.nav_search_input').val().trim();

        if (!str) {
          clearGlobalSearch();
          $('.nav_search_box').slideUp(500);
          $('.icon_clear').css({ display: 'none' });
          return;
        }
        $('.icon_clear').css({ display: 'block' });
        const _feed = feed;
        Promise.all([
          searchGames(str, 'ko'),
          searchBlogs(str, '36314,30601,36815,36816'),
          searchSupport(str, '36316,36317,36318,36319,36320,36321,36322,36323,36324')
        ]).then(([games, blogs, faqs]) => {
          if (_feed !== feed) return;
          updateGlobalSearch(
            { games, blogs, faqs },
            {
              empty: `<span>${str}</span>의 검색 결과가 없습니다. 키워드를 바꿔보세요.`,
              'empty-games': '관련 <span>게임이</span> 없습니다. 다른 키워드로  검색해보세요',
              'empty-blogs': '관련 <span>블로그가</span> 없습니다. 다른 키워드로 검색해보세요',
              'empty-faqs': '관련 <span>FAQ가</span> 없습니다. 다른 키워드로 검색해보세요',
              'games-title': '게임',
              'blogs-title': '블로그',
              'faqs-title': '큐앤에이',
              'see-all': '전체 보기'
            }
          );
          if (!$('.nav_search').hasClass('on')) return;
          $('.nav_search_box').slideDown(500);
        });
      }, 200);
    };
  })();

  // 搜索公用部分函数
  searchInit(handleSearch);

}
