import header from './html/header.html';
import footer from './html/footer.html';
import { searchInit } from '../../search_common';

import {
  appendNewsList,
  searchGames,
  searchBlogs,
  searchSupport,
  updateGlobalSearch,
  clearGlobalSearch
} from '../../utils';

export default function (conf) {
  const $header = $($(header));
  const $footer = $($(footer));
  $('body').append($header);
  $('body').append($footer);
  // 动态导入 footer 模块
  import('../../footer');
  // 底部新闻
  appendNewsList(34864, $footer.find('.footer-ajax.news'));
  // 底部常见问题
  appendNewsList(
    '36245,36246,36247,36248,36249,36250,36251,36252,36253',
    $footer.find('.footer-ajax.faq')
  );

  const handleSearch = (() => {
    let timer = 0;
    let feed = 0;
    return () => {
      if (timer) window.clearTimeout(timer);
      feed += 1;
      timer = window.setTimeout(() => {
        var str = $('.nav_search_input').val().trim();

        if (!str) {
          clearGlobalSearch();
          $('.nav_search_box').slideUp(500);
          $('.icon_clear').css({ display: 'none' });
          return;
        }
        $('.icon_clear').css({ display: 'block' });
        const _feed = feed;
        Promise.all([
          searchGames(str, 'fr'),
          searchBlogs(str, '36241,34864,36803,36804'),
          searchSupport(str, '36245,36246,36247,36248,36249,36250,36251,36252,36253')
        ]).then(([games, blogs, faqs]) => {
          if (_feed !== feed) return;
          updateGlobalSearch(
            { games, blogs, faqs },
            {
              empty: `Aucun résultat pour <span>${str}</span>, veuillez essayer avec un autre mot-clé`,
              'empty-games':
                'Aucun <span>jeu</span> trouvé, veuillez essayer avec un autre mot-clé',
              'empty-blogs':
                'Aucun <span>article</span> trouvé, veuillez essayer avec un autre mot-clé',
              'empty-faqs': 'Aucun <span>FAQ</span> trouvé, veuillez essayer avec un autre mot-clé',
              'games-title': 'Jeux',
              'blogs-title': 'Blog',
              'faqs-title': 'Support',
              'see-all': 'Voir tous'
            }
          );
          if (!$('.nav_search').hasClass('on')) return;
          $('.nav_search_box').slideDown(500);
        });
      }, 200);
    };
  })();

  // 搜索公用部分函数
  searchInit(handleSearch);
  
}
