import header from './html/header.html';
import footer from './html/footer.html';
import { searchInit } from '../../search_common';

import {
  appendNewsList,
  searchGames,
  searchBlogs,
  searchSupport,
  updateGlobalSearch,
  clearGlobalSearch,
} from '../../utils';

export default function(conf) {

  const $header = $($(header));
  const $footer = $($(footer));
  $('body').append($header);
  $('body').append($footer);

// 动态导入 footer 模块
import('../../footer');
  // 底部新闻
  appendNewsList(
    31440,
    $footer.find(".footer-ajax.news"),
  );
  // 底部常见问题
  appendNewsList(
    '35504,35503,35502,35501,35500,35499,35498,35497,35496',
    $footer.find(".footer-ajax.faq"),
  );

  const handleSearch = (() => {
    let timer = 0;
    let feed = 0;
    return () => {
      if (timer) window.clearTimeout(timer);
      feed += 1;
      timer = window.setTimeout(() => {
        var str = $('.nav_search_input').val().trim();

        if (!str) {
          clearGlobalSearch();
          $('.nav_search_box').slideUp(500);
          $('.icon_clear').css({ display: 'none' });
          return;
        }
        $('.icon_clear').css({ display: 'block' });
        const _feed = feed;
        Promise.all([
          searchGames(str, 'pt'),
          searchBlogs(str, '35096,31440,36809,36810'),
          searchSupport(str, '35504,35503,35502,35501,35500,35499,35498,35497,35496'),
        ]).then(([games, blogs, faqs]) => {
          if (_feed !== feed) return;
          updateGlobalSearch({ games, blogs, faqs }, {
            'empty': `Não há resultado de <span>${str}</span>, tente outra palavra-chave`,
            'empty-games': 'Não há <span>jogo</span> relacionado, tente outra palavra-chave',
            'empty-blogs': 'Não há <span>artigo</span> relacionado, tente outra palavra-chave',
            'empty-faqs': 'Não há <span>perguntas frequentes</span> relacionadas, tente outra palavra-chave',
            'games-title': 'Jogo',
            'blogs-title': 'Blog',
            'faqs-title': 'Suporte',
            'see-all': 'Veja tudo',
          });
          if (!$('.nav_search').hasClass('on')) return;
          $('.nav_search_box').slideDown(500);
        });
      }, 200);
    }
  })()

  // 搜索公用部分函数
  searchInit(handleSearch);

}
