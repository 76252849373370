export const searchInit = ((handleSearch) => { 
    $('.nav_search_input').bind('input propertychange', handleSearch);
    // 搜索相关交互
    $('.nav_search_btn').click(function () {
      handleSearch();
      $('.top-bar__main').addClass('overflow_y_hidden');
      $('.nav_search').addClass('on');
      $('.nav_search_input').focus();
      $('.jump_nav').hide();
      const val = $('.nav_search_input').val();
      if (val) {
        $('.nav_search_box').slideDown(500);
      }
    });
    // 清除按钮点击事件
    $('.icon_clear').click(function () {
      $('.nav_search_input').val('').trigger('input');
      clearGlobalSearch();
      $('.nav_search_box').slideUp(500);
      $('.icon_clear').css({ display: 'none' });
    });
    //关闭搜索弹窗
    $('.nav_search_result_bg,.icon_black').click(function () {
      $('.top-bar__main').removeClass('overflow_y_hidden');
      $('.nav_search_input').val('').trigger('input');
      $('.nav_search').removeClass('on');
      $('.nav_search_box').slideUp(500);
      $('.jump_nav').show(1000);
    });
})